body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  box-sizing: border-box;
}

.HomeView {
  padding: 16px 0;
}

.HomeView .HomeView-title {
  text-transform: uppercase;
  text-align: center;
}

.HomeView .HomeView-presentation p {
  font-size: 1.2rem;
  margin: .5rem;
  text-align: center;
  text-transform: uppercase;
}

.HomeView .HomeView-volumes {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.HomeView .HomeView-volume-actions {
  text-align: center;
}

.Page {
  display: block;
  margin: auto;
  max-height: calc(100vh);
  max-width: calc(100vw);
}

.HomeView .Page {
  max-height: calc(100vh - 81px);
  max-width: 100vw;
}

@media (min-width: 768px) {
  .HomeView .Page {
    max-width: 30vw;
  }
}

.button-row {
  display: flex;
  justify-content: center;
  margin: 1em 0;
}

.button-row .Button {
  margin: 0 4px;
}

.Button {
  border: none;
  border-radius: 4px;
  font-weight: bold;
  padding: 8px 16px;
  transition-duration: 0.25s;
  cursor: pointer;
  opacity: 1;
}

.Button-blue {
  background-color: #337ab7;
}

.Button-green {
  background-color: #5cb85c;
}

.Button-orange {
  background-color: #f0ad4e;
}

.Button:hover {
  opacity: 0.8;
}

.Button a {
  align-items: center;
  display: inline-flex;
  text-align: center;
  text-decoration: none;
  color: white;
}

.Button-icon {
  height: 16px;
  margin-right: 6px;
}
